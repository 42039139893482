// extracted by mini-css-extract-plugin
export var advantage = "home-module--advantage--1HIJf";
export var advantages = "home-module--advantages--37FhH";
export var background = "home-module--background--1MrOC";
export var backgroundBoy = "home-module--backgroundBoy--31ceH";
export var backgroundGirl = "home-module--backgroundGirl--1VW43";
export var courses = "home-module--courses--1gs9f";
export var dialog = "home-module--dialog--1jCIu";
export var intro = "home-module--intro--bIM0O";
export var joinImage = "home-module--joinImage--18duz";
export var joinUs = "home-module--joinUs--3mNee";
export var language = "home-module--language--351Wm";
export var languageImage = "home-module--languageImage--1iJb-";
export var languages = "home-module--languages--2oop0";
export var personImg = "home-module--personImg--1KoJv";