import React from "react"
import backgroundBoyImg from "../images/boy.png"
import backgroundGirlImg from "../images/girl.png"
import advImgOne from "../images/home/advantage_1.png"
import advImgTwo from "../images/home/advantage_2.png"
import advImgThree from "../images/home/advantage_3.png"
import advImgFour from "../images/home/advantage_4.png"
import { Link, navigate, graphql } from "gatsby"
import MainLayout from "../components/MainLayout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import {
  background,
  backgroundGirl,
  backgroundBoy,
  personImg,
  dialog,
  intro,
  courses,
  language,
  languages,
  languageImage,
  advantages,
  advantage,
  advantageOne,
  advantageTwo,
  advantageThree,
  advantageFour,
  joinUs,
  joinImage,
} from "../styles/home.module.css"

export default function Home({ data }) {
  const { joinUsImage, frenchImage, germanImage, englishImage, metaData } = data
  const { title, description } = metaData.siteMetadata

  return (
    <MainLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description}></meta>
        <meta
          name="keywords"
          content="linguolab, aprender idiomas, aprender, idiomas, cursos, clases, plataforma, online, zoom, virtual, linguo, ingles, inglés, frances, francés, aleman, alemán"
        />
        <html lang="es" />
      </Helmet>
      <section className={`${background} grid`}>
        <div className={backgroundGirl}>
          <img className={personImg} src={backgroundGirlImg} alt="girl" />
          <div className={`${dialog} dialog-left`}></div>
          {/* <div class="pointer pointer-left blurred"></div> */}
        </div>
        <div className={backgroundBoy}>
          <div className={`${dialog} dialog-right`}></div>
          {/* <div class="pointer pointer-right blurred"></div> */}
          <img className={personImg} src={backgroundBoyImg} alt="boy" />
        </div>
      </section>
      <section className={intro}>
        <p className="leading">
          ¡Aprende idiomas de una manera diferente e interactiva! Inscríbete a
          nuestros cursos de lengua extranjera para vivir una experiencia única,
          con una plataforma desarrollada especialmente para tu proceso de
          aprendizaje. Además contamos con profesores calificados en pedagogía
          de lengua extranjera, quienes te acompañarán durante todo tu proceso
          de aprendizaje.
        </p>
      </section>
      <section className={`grid ${advantages}`}>
        <div className={`${advantage} ${advantageOne}`}>
          <img src={advImgOne} alt="ventaja_1" />
          <p>
            Plataforma exclusiva, diseñada por profesionales en educación, para
            aprender de forma eficaz e intuitiva.
          </p>
        </div>
        <div className={`${advantage} ${advantageTwo}`}>
          <img src={advImgThree} alt="ventaja_3" />
          <p>Clases y cursos personalizados y semi personalizados</p>
        </div>
        <div className={`${advantage} ${advantageThree}`}>
          <img src={advImgTwo} alt="ventaja_2" />
          <p>
            Profesores capacitados, con herramientas innovadoras y capacitación
            en clases virtuales
          </p>
        </div>
        <div className={`${advantage} ${advantageFour}`}>
          <img src={advImgFour} alt="ventaja_4" />
          <p>
            Exámenes interactivos durante el proceso para controlar el avance
            por estudiante
          </p>
        </div>
      </section>
      <div className="layout">
        <section className={courses}>
          <h2>Nuestros cursos</h2>
          <div className={`${languages} grid`}>
            <div onClick={() => navigate("/courses")} className={language}>
              <GatsbyImage
                className={languageImage}
                image={germanImage.childImageSharp.gatsbyImageData}
                alt="aleman"
              />
              <h3>Alemán</h3>
              <p>
                Alemania se ha popularizado últimamente gracias a las diferentes
                ofertas de estudio gratuito y trabajo para profesiones variadas.
                Además, el alemán es un idioma interesante y lleno de belleza.
                Durante nuestros cursos aprenderás más sobre la cultura D-A-CH
                (Alemania, Austria y Suiza) y dominarás el idioma.
              </p>
            </div>
            <div onClick={() => navigate("/courses")} className={language}>
              <GatsbyImage
                className={languageImage}
                image={englishImage.childImageSharp.gatsbyImageData}
                alt="ingles"
              />
              <h3>Inglés</h3>
              <p>
                Popularmente conocido como el idioma más importante a nivel
                mundial. Es el idioma científico por excelencia, y uno de los
                requisitos para diferentes procesos de visado para muchos
                países. En nuestros programas de inglés nos enfocamos en
                transmitir un amplio componente cultural junto con el
                aprendizaje de la lengua.
              </p>
            </div>
            <div onClick={() => navigate("/courses")} className={language}>
              <GatsbyImage
                className={languageImage}
                image={frenchImage.childImageSharp.gatsbyImageData}
                alt="frances"
              />
              <h3>Francés</h3>
              <p>
                La famosa lengua del amor. Idioma de filósofos, científicos y
                artistas, ampliamente hablado alrededor del mundo en países como
                Canadá y Francia, e incluso en el continente africano. Durante
                nuestros cursos aprenderás tanto aspectos idiomáticos como
                culturales del francés y los diferentes países que hacen uso de
                este idioma.
              </p>
            </div>
          </div>
        </section>
        <section className={`grid layout ${joinUs}`}>
          <div>
            <h2>Asiste a nuestros cursos</h2>
            <p>
              El dominio de una lengua extranjera es una ventaja en diferentes
              aspectos y momentos de nuestra vida. Tanto para obtener un
              trabajo, como para ejercitar nuestra memoria por medio del
              aprendizaje de un nuevo idioma. <br />
              Nuestros cursos fueron creados exclusivamente para potenciar el
              aprendizaje a distancia, y nuestros métodos son planeados y
              estructurados para una óptima adquisición de una nueva lengua de
              manera remota.
            </p>
            <Link to="/courses">
              <button className="button"> Ir a los cursos &rarr;</button>
            </Link>
          </div>
          <div>
            <GatsbyImage
              image={joinUsImage.childImageSharp.gatsbyImageData}
              className={joinImage}
              imgStyle={{ objectFit: "contain", display: "block" }}
              alt="ventajas"
            />
          </div>
        </section>
      </div>
    </MainLayout>
  )
}

export const query = graphql`
  query HomeImages {
    joinUsImage: file(relativePath: { eq: "home/join-us.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    germanImage: file(relativePath: { eq: "home/german-logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    frenchImage: file(relativePath: { eq: "home/french-logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    englishImage: file(relativePath: { eq: "home/english-logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    metaData: site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
